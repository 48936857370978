import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {actions as uiActions, ModalTypes, selectors as uiSelectors} from 'shared/redux/ui';
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const styles = theme => ({
  currencyDropdown: {
    maxWidth: 1156,
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: 658,
      paddingRight: 25,
      marginTop: 20,
      display: 'flex',
      alignItems: 'flex-end'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '88vw'
    }
  },
  menu: {
    width: 90,
    position: 'absolute',
    right: 0,
    top: -30,
    cursor: 'pointer',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      position: 'static'
    }
  },
  dropdown: {
    width: 90,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  }
});

const currencies = ['PLN', 'EUR', 'USD'];

class CurrencyDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currency: this.props.currency
    };
    this.selectCurrency = this.selectCurrency.bind(this);
  }
  selectCurrency(currency) {
    this.setState({ open: false });
    this.props.onCurrencyChange(currency);
  }
  render() {
    const { classes, t } = this.props;
    const { open } = this.state;
    return (
      <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
        <Grid item direction="column" className={classes.currencyDropdown}>
          <Grid className={classes.menu}>
            <Grid container direction="row" alignItems="center" justify="flex-end" onClick={() => this.setState({ open: !open })}>
              <Typography style={{ userSelect: 'none' }}>{t("pricing.offers.currencyDropdown")}</Typography>
              {open ? <ExpandLessIcon/> : <ExpandMoreIcon />}
            </Grid>
            {open && <Grid className={classes.dropdown}>
              {currencies.map((currency) => <MenuItem selected={currency === this.props.currency} onClick={() => this.selectCurrency(currency)}>{currency}</MenuItem>)}
            </Grid>}
          </Grid>
        </Grid>
      </ClickAwayListener>
    );
  }
}


CurrencyDropdown.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  currency: PropTypes.string.isRequired,
  onCurrencyChange: PropTypes.func.isRequired,
};

const {
  getModal,
} = uiSelectors;

const {
  showModal,
  hideModal,
} = uiActions;

const mapStateToProps = state => ({
  modal: getModal(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(withStyles(styles)(CurrencyDropdown)));
