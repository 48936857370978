import React, { Component } from 'react';
import he from 'he';
import './index.scss';
import MetaTags from 'react-meta-tags';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { WithDependency } from "../../../shared/DependencyContext";
import { PlayerContext } from 'components/player/index.jsx';
import Slider from "react-slick";
import IconArrowSmall from 'static/home/icons/arrow-small.svg';
import IconViews from 'static/home/icons/views.svg';
import Config from 'config';
import { compose } from 'redux';
import { NavBarContext, NavBarStyles } from 'components/navBar';
import UserRoles from 'shared/UserRoles';
import Snackbar from '../../../components/snackbar/snackbar';
import { Api } from 'api';
import axios from 'axios';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import PresentationDashboard from 'components/presentation-dashboard';
import StartCoViewingModal from 'components/startCoViewingModal';
import MenuItem from '@material-ui/core/MenuItem';
import PrivtePresentationOverlay from '../../../components/privatePresentationOverlay/index';

const Profile = (props) => {
  return {
    getHref(url){
      return /^https?:\/\//i.test(url) ? url : 'http://' + url;
    },
    render() {
      let avatarSrc;
      if (props.avatarHash) {
        avatarSrc = AssetHelper.imagePath(props.avatarHash, ImageVariants.THUMBNAIL);
      } else {
        avatarSrc = AssetHelper.staticPath('/static/home/base-avatar.png');
      }

      return (
        <div className={"profile"}>
          <div className={"avatar"}>
            <img src={avatarSrc} />
          </div>
          <div className={"profile-details"}>
            {props.name && <h2 className={"profile-name"}>{he.decode(props.name)}</h2>}
            {props.phone && <div>{he.decode(props.phone)}</div>}
            {props.email && <div>{he.decode(props.email)}</div>}
            {props.description && <div>{ he.decode(props.description)}</div> }
            {props.address && <div>{he.decode(props.address)}</div>}
            {props.website && <div><a rel="noopener noreferrer" target="_blank" href={this.getHref(props.website)}>{props.website}</a></div>}
          </div>
        </div>
      );
    }
  }
};

let presentationHash;

class Presentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopPresentation: !!Config.home.shopPresentation,
      viewingModalOpen: false,
      viewingLoading: false,
      viewingHash: '',
      viewingId: '',
      sceneId: null,
      sceneName: null,
      isSnackbarOpen: false,
      vrAviable: false,
    };
    const { match, presentation, isFound } = this.props;

    if (location.search.indexOf('app') > -1) {
      document.body.classList.add('ui-hidden');
    }

		this.props.updateUi({footerVisible: false});

    this._onChangeScene = this._onChangeScene.bind(this);
    this._onChangePresentation = this._onChangePresentation.bind(this);
    this.closeViewingModal = this.closeViewingModal.bind(this);
    this.openViewingModal = this.openViewingModal.bind(this);
    this.startViewing = this.startViewing.bind(this);
    this.toggleSnackbar = this.toggleSnackbar.bind(this);
    this.isVRAviable = this.isVRAviable.bind(this);
    this.detectMobile = this.detectMobile.bind(this);

		presentationHash = match && match.params.hash;

		if(presentationHash && presentationHash.indexOf('-') > -1) {
			presentationHash = presentationHash.slice(0, presentationHash.indexOf('-'));
		}

    if (this.state.shopPresentation && presentationHash) {
      this.props.fetchShopPresentation({ hash: presentationHash });
    } else if (!presentation && isFound && presentationHash) {
		  this.props.fetchPresentation({ hash: presentationHash });
		}

    if (location.pathname.indexOf('livePreview') > -1) {
      this.intervalId = setInterval(() => {
        this.props.fetchEditedPresentations();
      }, 5000);
      this.props.fetchEditedPresentations();
    }

    this._increaseViewCount(presentationHash);
  }
  _setMenuContextLink(presentationId, presentationOwnerId, userProfileId, userRole) {
    const possibleRoles = [UserRoles.Editor, UserRoles.Manager, UserRoles.Owner, UserRoles.Admin, UserRoles.User];
    let links = [],
      editPresentationLinkVisible = presentationId &&
        possibleRoles.indexOf(userRole) > -1 &&
        presentationOwnerId === userProfileId,
      startCoviewingLinkVisible = presentationId &&
        presentationOwnerId === userProfileId;

    if (editPresentationLinkVisible) {
      const { handleCloseHeader } = this.props.navBar || {};
      links.push(<a href={`/panel/#/projects/${presentationId}/edit`}><MenuItem className="link" onClick={handleCloseHeader}>{this.props.t('navBar.editPresentation')}</MenuItem></a>);
    }

    if (startCoviewingLinkVisible) {
      links.push(<a onClick={() => this.openViewingModal(presentationId)}><MenuItem className="link">{this.props.t('navBar.startCoviewing')}</MenuItem></a>);
    }

    this.props.navBar.setContextLinks(links);
  }
  _shouldPlayerLoadPresentation(nextPresentation) {
    if (!this.props.presentation) {
      return true;
    } else {
      const hasPresentationIdChanged = this.props.presentation.id !== nextPresentation.id;

      if (hasPresentationIdChanged) {
        return true;
      } else {
        const hasPresentationContentChanged = JSON.stringify(this.props.presentation) !== JSON.stringify(nextPresentation);
        if (hasPresentationContentChanged) {
          return true;
        }
      }

      return false;
    }
  }
  _increaseViewCount(hash) {
    if (location.pathname.indexOf('/p/') > -1 ||
      location.pathname.indexOf('/embed/') > -1) {

      this.props.increasePresentationViewCount({hash: hash})
    }
  }
  _onChangeScene(e) {
    this.setState({
      sceneName: e.sceneName,
      sceneId: e.sceneId,
    });
  }
  _onChangePresentation(e) {
    const { hash, presentation } = e;
    const { history } = this.props;

    if (presentation) {
      history.push(`/p/${hash}`);
      history.go();
    } else {
      history.push(`/embed/${hash}`);
      history.go();
    }
  }
	componentWillReceiveProps(nextProps) {
    const { shopPresentation } = this.state;
    const { presentation, presentationMeta, user } = nextProps;
    const { id } = presentation || {};
    const { owner } = presentationMeta || {};
    const { id: presentationOwnerId } = owner || {};
    const { profile } = user || {};
    const { id: userProfileId } = profile || {};

		if (!nextProps.isLoading && !nextProps.isFound && !nextProps.passwordRequested) {
      this.props.history.push('/404');
		}
    if (!this.player && nextProps.player.instance) {
      this.player = nextProps.player.instance;

      if (location.pathname.indexOf('livePreview') > -1) {
        this.player.disableRotateScene();
      }

      if (this.props.presentation) {
        this.player.load(this.props.presentation, this.props.presentationMeta);
      }

      this.player.getInstance().on('changeScene', this._onChangeScene);
      this.player.getInstance().on('changePresentation', this._onChangePresentation);
    }
    if (nextProps.presentation && nextProps.presentation !== this.props.presentation) {
      const shouldPlayerLoadPresentation = this._shouldPlayerLoadPresentation(nextProps.presentation);
      if (shouldPlayerLoadPresentation) {
          this.player.load(nextProps.presentation, nextProps.presentationMeta);
      }
    }

    if (nextProps.presentation && nextProps.presentationMeta && nextProps.presentationMeta.owner && nextProps.user.role) {
      this._setMenuContextLink(nextProps.presentation.id, presentationOwnerId, userProfileId, nextProps.user.role);
    }

		if (this.props.editedSceneId !== nextProps.editedSceneId && this.player) {
      this.player.changeScene({sceneId: nextProps.editedSceneId});
    }

    if (nextProps.presentationMeta &&
      nextProps.presentationMeta.hash &&
      nextProps.match.params.hash &&
      nextProps.presentationMeta.hash !== nextProps.match.params.hash &&
      nextProps.match.params.hash !== this.props.match.params.hash) {
      const hash = nextProps.match.params.hash;

      if (shopPresentation) {
        this.props.fetchShopPresentation({ hash });
      } else {
        this.props.fetchPresentation({ hash });
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    const viewingParam = urlParams.get('coviewing');
    if(viewingParam && id && presentationOwnerId === userProfileId) {
      this.openViewingModal(id);
    }
	}
	componentDidMount() {
    const { navBar } = this.props;

    navBar.setStyle(NavBarStyles.minimal);
    if (window.location.href.includes('/p/')) {
      navBar.hide();
    } else {
      navBar.hidePageLinks();
    }

    if (this.player) {
      this.isVRAviable();
    }

    window.addEventListener('popstate', function(event) {
      window.location.reload();
    }, false);
  }
	componentWillUnmount() {
    window.removeEventListener("popstate", null);
    if (this.player) {
      this.player.unload();
    }
    this.player.getInstance().off('changeScene', this._onChangeScene);
    this.player.getInstance().off('changePresentation', this._onChangePresentation);
		this.props.updateUi({footerVisible: true});
  	this.props.clearPresentation();
  	if (this.intervalId) {
  	  clearInterval(this.intervalId);
    }
    this._setMenuContextLink();
	}
  detectMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  isVRAviable() {
    this.player && this.player.isVRCompatible().then(() => {
      if (this.detectMobile()) {
        this.setState({ vrAviable: false });
      } else {
        this.setState({ vrAviable: true });
        window.isVr = true;
      }
    }, () => {
      this.setState({ vrAviable: false });
    });
  }
  changeScene(sceneId) {
    if (this.player) {
      this.player.changeScene({sceneId: sceneId});
    }
  }
  closeViewingModal() {
    this.setState({
      viewingModalOpen: false,
      viewingHash: '',
      viewingId: '',
    });
  }
  openViewingModal(id) {
    if (!this.state.viewingLoading && !this.state.viewingHash) {
      const { handleCloseHeader } = this.props.navBar || {};
      this.setState({
        viewingLoading: true,
        viewingModalOpen: true,
      }, handleCloseHeader);
      axios.post(Api.viewings.create, {projectId: id }).then(
        response => {
          this.setState({
            viewingLoading: false,
            viewingHash: response.data.hash,
            viewingId: response.data.id,
          });
        },
        error => {
          this.props.notifications.add(this.props.t('viewing.messages.updateRequired'));
          this.setState({
            viewingModalOpen: false,
          });
        }
      );
    }
  }
  startViewing() {
    const { viewingHash } = this.state;

    if (!viewingHash) return;

    this.props.history.push(`/vh/${viewingHash}`);
  }

  toggleSnackbar() {
    this.setState(state => ({
      isSnackbarOpen: !state.isSnackbarOpen,
    }))
  }

  render() {
    const { presentationMeta, presentation, t, passwordRequested, fetchPresentation, error } = this.props,
      { sceneName, sceneId, viewingModalOpen, viewingLoading, viewingHash, viewingId, isSnackbarOpen, vrAviable } = this.state;
    vrAviable ? window.isVr = 'true' : null;
      const { description, alternativeDescription } = presentationMeta || {};

    let shareUrl = presentationMeta && `${location.protocol}//${location.host}/p/${presentationMeta.hash}`,
      fbUrl = 'https://www.facebook.com/dialog/share?app_id=1174425669285622&href=' + shareUrl + '&redirect_uri=' + shareUrl,
      gpUrl = 'https://plus.google.com/share?url=' + shareUrl,
      twUrl = 'http://www.twitter.com/share?url=' + shareUrl,
      ownerName,
			publicationDate,
      presentationName,
      presentationDescription,
      mapsContainerVisible = false,
      agentName,
      agentPhone,
      agentEmail,
      scenes,
      owner,
      gallery,
      leftSideVisible,
      leftSideClass = 'left-side',
      agentProfile,
      ownerProfile,
      descriptionContent;

    if (presentationMeta && Object.keys(presentationMeta).length > 0) {
      let date = new Date(presentationMeta.publicationDate);

      owner = presentationMeta.owner;
      if (owner) {
        ownerName = owner.name;

        ownerProfile = <Profile
          name={owner.name}
          description={owner.description}
          website={owner.website}
          address={owner.address}
          avatarHash={owner.avatar && owner.avatar.hash}
        />;
      }

      publicationDate = [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-');
      presentationName = presentation.name;

      if (sceneName) {
        presentationName += ' | ' + sceneName;
      }

      if (presentationMeta.agent) {
        agentName = (presentationMeta.agent.name && presentationMeta.agent.name.length > 0) ? presentationMeta.agent.name : null;
        agentPhone = (presentationMeta.agent.phone && presentationMeta.agent.phone.length > 0) ? presentationMeta.agent.phone : null;
        agentEmail = (presentationMeta.agent.email && presentationMeta.agent.email.length > 0) ? presentationMeta.agent.email : null;

        if (agentName || agentPhone || agentEmail) {
          agentProfile = (<Profile
            avatarHash={presentationMeta.agent.avatarHash}
            name={agentName}
            phone={agentPhone}
            email={agentEmail}
          />);
        }
      }

      if(alternativeDescription && i18next.language.includes('en')) {
        descriptionContent = alternativeDescription;
      } else {
        descriptionContent = description || '';
      }
      presentationDescription = (<span dangerouslySetInnerHTML={{  __html: descriptionContent }}></span>);

      if (presentation.scenes && presentation.scenes.length > 1 ) {
        mapsContainerVisible = true;

        scenes = presentation.scenes && presentation.scenes.map(scene => {

          let sphereResource = presentation.resources.spheres.find((res) => {
            return res.id == scene.spheres[0].resourceId;
          });

          let videoSphericalResource = presentation.resources.videosSpherical.find((res) => {
            return res.id == scene.spheres[0].videoResourceId;
          });

          let resource = sphereResource ? sphereResource : videoSphericalResource;

          if(!resource) return;

          let styles = {
            background: `url(${AssetHelper.imagePath(resource.hash, ImageVariants.THUMBNAIL)})`,
            backgroundSize: '400% 400%',
            backgroundPosition: 'center center'
          };

          let sceneName = scene.name;
          if (sceneName.length > 45) {
            sceneName = `${sceneName.slice(0, 44)}...`;
          }

          return (
            <div className={"single-map" + (scene.id == sceneId?' isSelected':'')} key={scene.id} onClick={() => {this.changeScene(scene.id)}}>
              <div className="scene-background" style={styles}></div>
              <div className="scene-name">
                <span>{sceneName}</span>
              </div>
            </div>);
          });
      }
    }

    if (presentationMeta && presentationMeta.gallery && presentationMeta.gallery.length) {
      const SampleArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
          >
            <IconArrowSmall />
          </div>
        );
      }

      gallery = (<Slider
        accessibility={false}
        dots={true}
        prevArrow={<SampleArrow />}
        nextArrow={<SampleArrow />}
      >
        {presentationMeta.gallery.map((galleryItem) => {
          return (
            <div key={galleryItem.hash}>
              <img src={AssetHelper.imagePath(galleryItem.hash, ImageVariants.NORMALIZED)} />
            </div>
          )
        })}
      </Slider>);
    }

    leftSideVisible = (gallery || presentationDescription || agentProfile || ownerProfile);

    if (!mapsContainerVisible) {
      leftSideClass += ' left-side--full';
    } else if (gallery) {
      leftSideClass += ' left-side--wide';
    }

    const metaOwner = this.props.presentationMeta && this.props.presentationMeta.owner && this.props.presentationMeta.owner.name ? `${this.props.presentationMeta.owner.name} ` : '';
    const metaName = this.props.presentationMeta && this.props.presentationMeta.name ? `${this.props.presentationMeta.name} - ` : '';
    const metaDescription = this.props.presentationMeta && this.props.presentationMeta.description ? this.props.presentationMeta.description : '';
    const alias = presentationMeta && presentationMeta.alias ? `-${presentationMeta.alias}` : '';
    const canonicalPresentationLink = `${window.location.origin}/p/${presentationHash}${alias}`;

    return (
      <div>
        <MetaTags>
          <title>{metaOwner}{metaName}{t("common.evryplaceVirtualTour")}</title>
          <meta name="description" content={metaDescription} />
          <link rel="canonical" href={canonicalPresentationLink} />
        </MetaTags>
      {passwordRequested ?
        <PrivtePresentationOverlay fetchPresentation={fetchPresentation} hash={presentationHash} error={error}/> :
        <div className={'presentation-page'}>
        <PresentationDashboard
          player={this.player}
          changeSceneWithArrowsEnabled
          presentation={presentation}
          vrButtonVisible
          metatags={presentationMeta}>
          <div className="content-row">
            {mapsContainerVisible && <div className={"maps-container"}>
              {scenes}
            </div>}
            {leftSideVisible &&
            <div className={leftSideClass}>
              {gallery}
              {presentationDescription && <div className={"description"}>
                {presentationDescription}
              </div>}
              {agentProfile}
              {ownerProfile}
            </div>}
          </div>
          <div className={"details-box"}>
            <div className="details-content">
              <IconViews />
              <span>{presentationMeta && presentationMeta.viewCount}</span>
            </div>
            <div className="details-content">{publicationDate}</div>
            <div className="details-content">{ownerName}</div>
          </div>
        </PresentationDashboard>
          <StartCoViewingModal
            open={viewingModalOpen}
            onClose={this.closeViewingModal}
            loading={viewingLoading}
            hash={viewingHash}
            id={viewingId}
            presentationId={presentation && presentation.id}
            onStart={this.startViewing}
            openSnackbar={this.toggleSnackbar}
          />
        <Snackbar open={isSnackbarOpen}
                  onClose={this.toggleSnackbar}
                  autoHideDuration={4000}
                  anchorOrigin={{vertical: 'top', horizontal: 'center',}}
                  message={t('presentation.shareCoviewingNotification')}>
        </Snackbar>
      </div>
      }
      </div>
    );
  }
}

function PresentationNavBar(props) {
  return <NavBarContext.Consumer>
    {navBar => (
      navBar ? <Presentation
        {...props}
        navBar={navBar}
      /> : <div></div>
    )}
  </NavBarContext.Consumer>;
}

function PlayerPresentation(props) {
  return (
    <PlayerContext.Consumer>
      {player => (
        <PresentationNavBar
          {...props}
          player={player.player}
        />
      )}
    </PlayerContext.Consumer>
  );
}

export default compose(
  translate('translations'),
  )(WithDependency(PlayerPresentation));
