import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'shared/redux/presentation';
import Presentation from './containers/index.jsx';
import { actions as uiActions } from 'shared/redux/ui';
import { selectors as authSelectors } from 'shared/redux/auth';

const {
  getPresentation,
  getPresentationMeta,
  getEditedSceneId,
  isLoading,
  isFound,
  passwordRequested,
  getError,
} = selectors;

const {
  fetchEditedPresentations,
  fetchPresentation,
  clearPresentation,
  increasePresentationViewCount,
} = actions;

const {
  updateUi,
} = uiActions;

const {
  getCurrentUser
} = authSelectors;

const mapStateToProps = state => ({
  presentation: getPresentation(state),
  presentationMeta: getPresentationMeta(state),
  editedSceneId: getEditedSceneId(state),
  isLoading: isLoading(state),
  isFound: isFound(state),
  user: getCurrentUser(state),
  passwordRequested: passwordRequested(state),
  error: getError(state),

});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchPresentation,
    fetchEditedPresentations,
    clearPresentation,
		updateUi,
    increasePresentationViewCount,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Presentation);

